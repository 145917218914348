const breakpoints = {
    xs: 576,
    sm: 768,
    md: 940,
    lg: 1280,
    xlg: 1920
};


module.exports = {
    "breakpoints": breakpoints
}
