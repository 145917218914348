import '../scss/index.scss';
import './contact-form.js';

import Utils from './utils';
import screenDetector from './screen-detector'; // initializes screensize etc.
import Swiper, { Navigation, Autoplay } from 'swiper';

const urlParams = Utils.getQueryStringParams(window.location.search);
const login = urlParams.login;

import modals from './modals.js';

$(document).ready(() => {
    let swiper;

    modals.initModals();

    Swiper.use([Navigation, Autoplay]);

    swiper = new Swiper('.swiper-container', {
        direction: 'horizontal',
        loop: false,
        slidesPerView: 1,
        spaceBetween: 10,
        watchOverflow: false,

        autoplay: {
            delay: 3000,
            reverseDirection: true,
            pauseOnMouseEnter: true,
        },

        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },

        breakpoints: {
            // when window width is >= 768px
            767: {
                slidesPerView: 2
            },
        }
    });

    const setAutoPlay = () => {
        const gallery = document.querySelector('#linear-gallery > .trigger-line');

        if (gallery) {
            if(Utils.elementInViewport(gallery)) {
                swiper.autoplay.start();
            } else {
                swiper.autoplay.stop();
            }
        }
    }

    document.addEventListener('scroll', () => Utils.throttelFn(setAutoPlay, 500));

    $('.hamburger-icon').on('click', () => {
        $('.mobile.dropdown-menu').toggle();
    });

    const $greyScales = $('.tooltag .greyscale');
    const $colored = $('.tooltag .colored');
    const $hoverTarget = $('.tooltags .hover-target');

    $hoverTarget.mouseenter(function() {
        const dataId = $(this).data('id');

        $('.colored[data-id="' + dataId + '"]').show();
        $('.greyscale[data-id="' + dataId + '"]').hide();
    });

    $hoverTarget.mouseleave(() => {
        $greyScales.show();
        $colored.hide();
    });
});
