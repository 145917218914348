class Modals {
    show(targetRef) {
        let $target = null;

        switch(typeof targetRef){
            case 'string':
                $target = $('[data-modal="'+targetRef+'"]');
                break;
            case 'object':
                $target = targetRef;
                break;
            default:
                $target = null;
        }

        const $modals = $('[data-modal]');

        if(typeof $modals !== 'undefined' && $modals.length) {
            $modals.not($target).addClass('hide');
            $target.toggleClass('hide');
            if ($modals.filter('.hide').length < $modals.length) {
                $modals.parent().addClass('active');
            } else {
                $modals.parent().removeClass('active');
            }
        }
    };

    initModals() {
        const self = this;
        const $modals = $('[data-modal]');

        $modals.each((index, el) => {
            let $target = $(el);
            var modalId = $target.attr('data-modal');
            var $triggers = $('[data-trigger-modal="'+modalId+'"]');

            $triggers = $triggers.add($target.find('.close'));
            $triggers.on('click', function(e){
                e.preventDefault();
                self.show($target);
                return false;
            });
        });
    };
}

export default (new Modals);
