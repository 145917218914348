console.log('connected1');

$(document).ready(() => {
    const setContactFeedback = (success, body) => {

        if (typeof success !== 'undefined' && typeof body === 'string') {
            $('.form-feedback p').html(`<p class="${success? 'success' : 'failed'}">${body}</p>`);
        } else {
            $('.form-feedback p').html('<p class="failed">Something went wrong, please contact me directly at ' +
                '<a href="mailto:b.persoon@lemursdev.com">b.persoon@lemursdev.com</a>.' +
                '</p>');
        }
    }

    const sendContactForm = ({senderName, senderEmail, senderContent}) => {
        const {hostname, protocol} = window.location;

        const query = `sender-name=${senderName}&sender-email=${senderEmail}&sender-content=${senderContent}`;

        $.post({
            url: `${protocol}//${hostname}/contact/message?${query}`,
        }).done((result) => {
            const { success, data, errormessage} = result;

            if (success) {
                let name = data.name,
                    message = `Your message was sent succesfully. You will receive a reply as soon as possible.`;

                setContactFeedback(result, message);
                $('#contact_form').trigger("reset");

                $('.hide-after-send').hide();
            } else {
                setContactFeedback(result, errormessage);
            }
        });
    }

    $('#contact_form').on('submit', e => {
        e.preventDefault();

        const inputData = $("#contact_form input").serializeArray();
        const textAreaData = $("#contact_form textarea").serializeArray();

        const params = {
            senderName: inputData[0].value,
            senderEmail: inputData[1].value,
            senderContent: textAreaData[0].value
        }

        sendContactForm(params);
    });
});
