export default {
    getQueryStringParams: (query) => {
        return query
            ? (/^[?#]/.test(query) ? query.slice(1) : query)
                .split('&')
                .reduce((params, param) => {
                        let [key, value] = param.split('=');
                        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                        return params;
                    }, {}
                )
            : {}
    },
    elementInViewport: (el) => {
        const width = el.offsetWidth;
        const height = el.offsetHeight;

        let top = el.offsetTop;
        let left = el.offsetLeft;

        while (el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
            left += el.offsetLeft;
        }

        return (
            top >= window.pageYOffset &&
            left >= window.pageXOffset &&
            (top + height) <= (window.pageYOffset + window.innerHeight) &&
            (left + width) <= (window.pageXOffset + window.innerWidth)
        );
    },
    throttelFn: (callback, delay) => {
        if (!window.i_am_throttling) {
            window.i_am_throttling = true;

            callback();
            setTimeout(() => window.i_am_throttling = false, delay);
        }
    }
}
