import { screenMatcher } from './screen-matcher';

class ScreenDetector {
    constructor(){
        this.screenSize = '';
        this.oldScreenSize = '';
        this.t_screenResize;
        this.setScreenSize();
        this.setScreenSizeOnResize();
    }
    getScreenSize() {
        return this.screenSize;
    }
    isSmallScreen() {
        return $.inArray(this.screenSize, ['small', 'medium']) !== -1;
    }
    setScreenSize() {
        this.oldScreenSize = this.screenSize;
        this.screenSize = screenMatcher();
        let $body = $('body');
        let screenSizeClass = `${this.screenSize}-screen`;

        /* add bodyclass for screen size (+ manage resizing) */
        if (!this.oldScreenSize.length) {
            $body.addClass(screenSizeClass);
            $body.trigger('screenChanged');
        } else if (this.screenSize != this.oldScreenSize) {
            $body
                .attr('class', function(i, c){
                    return c.replace(/\S+-screen($|\s)/g, screenSizeClass);
                })
                .data('screenSize', this.screenSize);
            $body.trigger('screenChanged');
        }
    }
    setScreenSizeOnResize() {
        $(window).resize(() => {
            clearTimeout(this.t_screenResize);
            let that = this;
            this.t_screenResize = setTimeout(function(){
                that.setScreenSize();
            }, 200);
        });

    }
}

export default (new ScreenDetector);
